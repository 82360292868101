import React, { Component, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import GoBack from '@components/partials/GoBack';
import { Link } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';

function Page2({ t }) {
	const history = useHistory();

	// const [currentLocation, setCurrentLocation] = useState(null)

	// // const currentLocation =  window.location.href

	// useEffect(()=>{
	// 	const currentLocationVeriable =  window.location.href
	// 	setCurrentLocation(currentLocationVeriable)
	// }, [])

	const currentLocation =  window.location.href



	const onCollectTicket = () => {
		history.push('/collect-login');
	};

	const onBookNewTicket = () => {
		history.push('/movies');
	};

	return (
		<Container fluid className="parent">
			<div className="gb"><GoBack className=""/></div>
			<div className="">
			<div className="page2_container">
				<Link to="/" className="page2_movie_header">
					<h2 className="small_text">{t('common.WELCOME TO')}</h2>
					<div className="logo2"></div>
					{/* <h2 className="bigg_text">{t('common.OASIS MALL')}</h2> */}
					{
						 currentLocation == "https://jeddah-kiosk-cinepolis.binarynumbers.io/book-show" ? 
						<h2 className="bigg_text">{t('common.Cinepolis-Jameah-Plaza-Jeddah')}</h2>: 
						null
					}
					{
						 currentLocation == "https://kiosk-cinepolis.binarynumbers.io/book-show" ? 
						<h2 className="bigg_text">{t('common.Al-Hokair-Time')}</h2>: 
						null
					}
					{
						 currentLocation == "https://dammam-kiosk-cinepolis.binarynumbers.io/book-show" ? 
						<h2 className="bigg_text">{t('common.OASIS MALL')}</h2>: 
						null
					}
				</Link>
				<div className="page2_body">
					<div className="choose_action">{t('collect_or_book.CHOOSE YOUR ACTION')}</div>
					<div className="huge_buttons">
						<button onClick={onCollectTicket} className="left_button hb">
							{t('collect_or_book.COLLECT YOUR TICKET')}
						</button>
						<button onClick={onBookNewTicket} className="right_button hb">
							{t('collect_or_book.BOOK A NEW SHOW')}
						</button>
					</div>
				</div>
			</div>
		</div>
		</Container>
	);
}

export default withNamespaces()(Page2);
