import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import screen from '../assets/imgs/svgs/screen.svg';
import { fetchShowDetails } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import ip1 from '../assets/imgs/ip1.jpg';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import moment from 'moment';

function FinalTicket(props) {
	const { transaction_id } = props.match.params;
	const t = props.t;
	const dispatch = useDispatch();
	const showDetails = useSelector((state) => state.booking.show_details);
	const history = useHistory();

	const redirectToHome = () => {
		history.push('/');
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(fetchShowDetails({ transaction_id }, redirectToHome));
	}, [transaction_id]);
	return (
		<div className="">
			<MovieHeader />
			<div className="bg-light-black">
				<Container fluid className="py-3 mb-4">
					<Row className="my-5">
						<Col className="text-center">
							<p className="kumbh fw-bold">
								{t('Your booking is successful')},<br /> {t('enjoy the show')}.
							</p>
						</Col>
					</Row>

					<Row className="">
						<Col xs={8} className="mx-auto">
							<Row className="mxh-70 bg-pb d-flex align-items-center py-3">
								<Col>
									<p className="kumbh fw-bold text-center">{t('YOUR TICKET')}</p>
								</Col>
							</Row>
							<Row>
								<Col className="px-0">
									<div className="hero">
										<div className="imgOverlay d-flex justify-content-center">
											<img className="" src={ip1} height="360px" />
										</div>
										<div className="hero-text px-3 d-flex flex-column justify-content-end">
											<Container fluid>
												<Row className="pb-4">
													<Col>
														<Row className="pb-3">
															<Col md={8}>
																<div className="d-flex align-items-center">
																	<img src={ip1} alt="title" className="mxh-128" height="128px" />
																	<div className="d-flex flex-column justify-content-end pl-3 kumbh fw-reg frxl-12 frs-10">
																		<p className="fw-bold frxl-18 frs-16">
																			{showDetails.movie_title}
																		</p>
																		<p className="mb-2">{showDetails.cinema_name}</p>
																		<p className="mb-2">
																			{moment(showDetails.Showtime).format('ddd, Do, MMM, YYYY')}
																		</p>
																		<p className="mb-2">
																			{t('Seats')} {showDetails.seats_name}
																		</p>
																		{/* <p className="">4D E-motion</p> */}
																	</div>
																</div>
															</Col>
															<Col md={4} className="text-right">
																<div className="d-flex flex-column align-items-end">
																	<img
																		src={ip1}
																		height="128px"
																		width="128px"
																		className=""
																		alt="picture"
																	/>
																	<p className="kumbh fw-reg">
																		{t('Scan this code and recieve the ticket on your phone')}
																	</p>
																</div>
															</Col>
														</Row>
													</Col>
												</Row>
											</Container>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="bgb py-5 mb-4">
								<Col xs={8} className="br-1">
									<div className="px-3 frxl-20 frl-18 frs-16 text-light kumbh fw-reg">
										<p className="ls-2 fw-bold pb-3 bb-1 mb-3">{t('Order Details')}</p>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="">
												{t('Tickets')} {showDetails.seats_name}
											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{(showDetails.ticket_price_in_cents - showDetails.ticket_taxes_in_cents) /
													100}{' '}
												SAR
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="">
												{t('Food and Beverages')}(x{showDetails.fnb_items_count})
											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{(showDetails.fnb_price_in_cents - showDetails.fnb_taxes_in_cents) / 100} SR
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="frxl-18 frl-16 frs-14">
												{t('Taxes')}
												<br />
												<span className="blurred-white frxl-14 frl-12 frm-10 frs-8">(15%)</span>
											</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{(showDetails.fnb_taxes_in_cents + showDetails.ticket_taxes_in_cents) / 100}{' '}
												SR
											</span>
										</div>
										{/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
                                            <span className="frxl-18 frl-16 frs-14">Discount Applied</span>
                                            <span className="fw-bold frxl-24 frl-22 frm-18 frs-14">-15 SR</span>
                                        </div> */}
										<div className="pb-3 d-flex justify-content-between bb-1">
											<span className="frxl-18 frl-16 frs-14">{t('Total')}</span>
											<span className="fw-bold frxl-24 frl-22 frm-18 frs-14">
												{showDetails.transaction_net_price} SAR
											</span>
										</div>

										{/* <div className="mt-3 pb-3 d-flex justify-content-between bb-1">
                                            <span className="frxl-18 frl-16 frs-14">QR Code</span>
                                            <img src={ip1} width="150" height="150" />
                                        </div> */}
									</div>
								</Col>
								<Col xs={4} className="">
									<div className="px-4 kumbh fw-reg">
										<div className="d-flex flex-column mt-3 mb-5">
											<span className="blurred-white">{t('Booking Date')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">
												{moment(showDetails.transaction_date_time).format('DD/MM/YYYY')}
											</span>
										</div>
										<div className="d-flex flex-column my-5">
											<span className="blurred-white">{t('Booking Time')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">
												{moment(showDetails.transaction_date_time).format('HH:mm A')}
											</span>
										</div>
										<div className="d-flex flex-column my-5">
											<span className="blurred-white">{t('Payment Method')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">Credit Card</span>
										</div>
										<div className="d-flex flex-column mt-5">
											<span className="blurred-white">{t('Ticket ID')}</span>
											<span className="fw-bold frxl-28 frl-24 frs-20">{transaction_id}</span>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="mt-5 justify-content-center">
								<Col md={3}>
									<span className="ls-3 frxl-24 frl-22 frs-18 kumbh fw-bold p-3 blue-btn">
										{t('PRINT TICKET')}
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withNamespaces()(FinalTicket);
