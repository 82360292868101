import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMovieDetails, fetchShowTimes } from '@store/movies/moviesActions';
import { timeConvert, isImageURLNull, translateSessionAttribute } from '@helper/formatting.js';
import { useHistory } from 'react-router-dom';
import moment, { locale } from 'moment';
import MovieHeader from '@components/partials/MovieHeader';
import { globalConfigContext } from '@context/GlobalConfigContext';
import CSpinner from '@components/partials/Spinner';
import { withNamespaces } from 'react-i18next';
import GoBack from '@components/partials/GoBack';
import i18n from '../plugins/i18n';
import { Row, Container, Col } from 'react-bootstrap';

import movieDef from '@assets/imgs/movie-default-portrait.jpg';

function MovieDetailsContainer(props) {
	const { arabic, setArabic } = useContext(globalConfigContext);
	const { movie_id } = props.match.params;
	console.log('movie_id :>> ', movie_id);
	return (
		<div>
			<MovieHeader />
			<Container fluid>
				<div>
					<div className="main_container">
						{/* <GoBack t={props.t} to="prev" /> */}
						<MovieDetails movie_id={movie_id} t={props.t} />
						<GoBack t={props.t} to="prev" />
					</div>
				</div>
			</Container>
		</div>
	);
}

const MovieDetails = ({ movie_id, t }) => {
	const { arabic, currency } = useContext(globalConfigContext);

	const [currentShowDate, setCurrentDate] = useState(
		moment.utc().locale('en').format('YYYY-MM-DD')
	);
	const [modifiedShowTimes, setModifiedShowTimes] = useState(null);
	const [showSpinner, setShowSpinner] = useState(false);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchMovieDetails({ movie_id }));
	}, [movie_id]);

	useEffect(() => {
		setShowSpinner(true);
		dispatch(
			fetchShowTimes({
				show_date_kiosk: currentShowDate,
				movie_id: movie_id,
				cinema_id: null,
				experience: null,
			})
		).finally(() => setShowSpinner(false));
	}, [currentShowDate, movie_id]);

	const movieDetails = useSelector((state) => state.movies.movie_details);
	const ShowTimes = useSelector((state) => state.movies.show_times);
	useEffect(() => {
		let newShowTimes = [];
		for (let i = 0; i < ShowTimes.length; i++) {
			let newCinemaObj = { CinemaId: ShowTimes[i].CinemaId };
			newShowTimes.push(newCinemaObj);
			if (
				ShowTimes[i].Screens &&
				ShowTimes[i].Screens.length > 0 &&
				ShowTimes[i].Screens[0].ShowTimes.length > 0
			) {
				newCinemaObj.CinemaName = ShowTimes[i].Screens[0].ShowTimes[0].CinemaName;
				newCinemaObj.CinemaAddress = ShowTimes[i].Screens[0].ShowTimes[0].cinema_address;
			}

			newCinemaObj.Shows = {};
			for (let screen of ShowTimes[i].Screens) {
				for (let showTime of screen.ShowTimes) {
					if (showTime.SessionAttribute) {
						newCinemaObj.Shows[showTime.SessionAttribute]
							? newCinemaObj.Shows[showTime.SessionAttribute].push(showTime)
							: (newCinemaObj.Shows[showTime.SessionAttribute] = [showTime]);
					} else {
						newCinemaObj.Shows['STANDARD']
							? newCinemaObj.Shows['STANDARD'].push(showTime)
							: (newCinemaObj.Shows['STANDARD'] = [showTime]);
					}
				}
			}
		}

		setModifiedShowTimes(newShowTimes);
	}, [ShowTimes]);
	return (
		<div className="movieDetails_mainContainer">
			<CSpinner active={showSpinner} />
			<Row className="w-100">
				<Col className="px-0">
					<div className="movieDetails_container">
						<Col xs={4} className={`${arabic ? 'pr-0' : 'pl-0'}`}>
							<div className="thumbnail">
								<img
									className="banner"
									src={
										!isImageURLNull(movieDetails.movie_image_url_1)
											? movieDetails.movie_image_url_1
											: movieDef
									}
									alt={movieDetails.movie_title}
								/>
							</div>
						</Col>
						<Col xs={8} className={`${arabic ? 'pl-0' : 'pr-0'}`}>
							<div className="details_container">
								<div className="details_header">
									<div className="header_left">
										<h1>{arabic ? movieDetails.movie_title_ar : movieDetails.movie_title}</h1>
										<p className="frs-20">{movieDetails.movie_lang}</p>
									</div>
									{/* <div className="header_right">
							<div>
								<p>{movieDetails.Rating}</p>
								<p className="time">{timeConvert(movieDetails.run_time)}</p>
							</div>
						</div> */}
									<div className="group px-2">
										<span className={`${arabic ? 'border-left pl-2' : 'border-right pr-2'}`}>
											{movieDetails.Rating}
										</span>
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{timeConvert(movieDetails.run_time)}
										</span>
									</div>
								</div>

								<div className="details_body mt-auto">
									<p className="title">{t('movie_details.Synopsis')}</p>
									<p className="content text-limit">
										{arabic ? movieDetails.movie_description_sr : movieDetails.movie_description}
									</p>
									<p className="title mt-auto">{t('movie_details.Cast')}</p>
									<p className="content">
										{arabic ? movieDetails.movie_cast_ar : movieDetails.movie_cast}
									</p>
									{/* <p className="title">Directed By</p>
									<p className="content">Michael Bay</p> */}
								</div>
							</div>
						</Col>
					</div>
				</Col>
			</Row>

			<Row className="w-100">
				<Col className="movieFeatures_container">
					<div className="features_header">
						<div className="featureHeader_left">
							<p>{t('movie_details.Date')}</p>
							<h2>{moment.utc(currentShowDate).format('Do MMMM, YYYY')}</h2>
						</div>
						{/* <div className="featureHeader_right">
							<p>{t('movie_details.Choose Language')}</p>
							<div className="featureHeader_right_buttons">
								<button className="language_button active">{t('movie_details.ENGLISH')}</button>
								<button className="language_button">{t('movie_details.ARABIC')}</button>
							</div>
						</div> */}
					</div>

					<div className="features_body">
						<p className="pb-2">{t('movie_details.Choose Show Time')}</p>
						{modifiedShowTimes && modifiedShowTimes.length > 0
							? modifiedShowTimes.map((cinema, cinemaIndex) =>
									Object.keys(cinema.Shows).map((showtypeKey, showtypeKeyIndex) => (
										<ShowTimings
											key={showtypeKey}
											plan={showtypeKey}
											cinemaId={cinema.CinemaId}
											timings={cinema.Shows[showtypeKey]}
										/>
									))
							  )
							: null}
					</div>
				</Col>
			</Row>
		</div>
	);
};

const ShowTimings = ({ plan, cinemaId, timings }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const history = useHistory();

	const onClickShowTime = (SessionId) => {
		history.push('/seat-layout/' + cinemaId + '/' + SessionId);
	};

	return (
		<div className="showTimings">
			<h1>{translateSessionAttribute(arabic, plan)}</h1>
			<div className="time_slots">
				{timings.map((t, index) =>
					moment(t.Showtime).isBefore(moment()) ? (
						<button key={index} className="chooseTime_button showtime inactive">
							{moment.utc(t.Showtime).format('HH:mm')}
						</button>
					) : (
						<button
							key={index}
							onClick={() => onClickShowTime(t.SessionId)}
							className="chooseTime_button showtime"
						>
							{moment.utc(t.Showtime).format('HH:mm')}
						</button>
					)
				)}
			</div>
		</div>
	);
};
export default withNamespaces()(MovieDetailsContainer);
