import {
    FETCH_NOW_PLAYING_MOVIES, FETCH_NOW_PLAYING_ERROR, FETCH_BANNERS, FETCH_BANNERS_ERROR,
    FETCH_MOVIE_DETAILS, FETCH_MOVIE_DETAILS_ERROR, FETCH_SHOW_TIMES, FETCH_SHOW_TIMES_ERROR, FETCH_CURRENT_MOVIE_SHOW_DATES,
    SET_MOVIE_DETAILS_LOADER,
    SET_SHOW_TIMES_LOADER,
    CLEAR_MOVIE_DETAILS_ERROR
} from "./moviesTypes";
import MovieService from "@apiService/MovieService";

const fetchNowPlayingSuccess = (data) => {
    return {
        type: FETCH_NOW_PLAYING_MOVIES,
        payload: data
    }
}

const fetchNowPlayingError = (err) => {
    return {
        type: FETCH_NOW_PLAYING_ERROR,
        payload: err
    }
}

const fetchMovieDetailsSuccess = (data) => {
    return {
        type: FETCH_MOVIE_DETAILS,
        payload: data
    }
}

const fetchMovieDetailsError = (error) => {
    return {
        type: FETCH_MOVIE_DETAILS_ERROR,
        payload: error
    }
}

const fetchShowTimeSuccess = (data) => {
    return {
        type: FETCH_SHOW_TIMES,
        payload: data
    }
}

const fetchShowTimeError = (error) => {
    return {
        type: FETCH_SHOW_TIMES_ERROR,
        payload: error
    }
}

const fetchCurrentMovieShowDates = (payload) => ({
    type: FETCH_CURRENT_MOVIE_SHOW_DATES,
    payload
})

const setShowTimesLoader = (payload) => ({
    type: SET_SHOW_TIMES_LOADER,
    payload
});

const setMovieDetailsLoader = (payload) => ({
    type: SET_MOVIE_DETAILS_LOADER,
    payload
})

export const clearMovieDetailsError = (payload) => ({
    type: CLEAR_MOVIE_DETAILS_ERROR
})

export const fetchNowPlayingMovies = (payload) => {
    return async function (dispatch) {
        try {
            const response = await MovieService.GetNowPlayingMovies(payload);
            const { data } = response;
            if (data.status && data.data.length > 0) {
                dispatch(fetchNowPlayingSuccess(data.data))
            }
        } catch (err) {
            dispatch(fetchNowPlayingError(err.message))
        }
    }
}

export const fetchMovieDetails = (payload) => {
    return function (dispatch) {
        dispatch(setMovieDetailsLoader(true))
        MovieService.GetMovieDetails(payload)
            .then((response) => {
                const { data } = response;
                if (data.status && data.data.length > 0) {
                    dispatch(fetchMovieDetailsSuccess(data.data[0]))
                }
            }).catch((err) => {
                dispatch(fetchMovieDetailsError(err.message))
            });
    }
}

export const fetchShowTimes = (payload) => {
    return async function (dispatch) {
        try {
            dispatch(setShowTimesLoader(true));
            const response = await MovieService.GetShowTimes(payload);
            const { data } = response;
            if (data.status) {
                dispatch(fetchShowTimeSuccess(data.data))
            }
        } catch (err) {
            dispatch(fetchShowTimeError(err.message))
        }
    }
}

export const fetchShowDates = (payload) => {
    return function (dispatch) {
        if (!payload.movie_id || !payload.cinema_id) {
            return;
        }
        MovieService.GetShowDates(payload)
            .then(response => {
                const { data } = response;
                if (data.data) {
                    dispatch(fetchCurrentMovieShowDates(data.data))
                }
            }).catch(err => {
                console.log(err.response ? err.response : err);
            });
    }
}