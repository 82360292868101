import axios from 'axios';
import { BASEURL } from '../config';
import queryString from 'query-string';
import { CinemaService } from '@apiService/tokenService';

export default () => {
	const instance = axios.create({
		baseURL: `${BASEURL}`,
	});
	// instance.defaults.headers.common["Authorization"] = 'JWT_TOKEN';
	// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	instance.defaults.headers.common['KIOSK'] = 'Y';

	const cinema_id = CinemaService.getCinemaId();

	if (cinema_id) {
		instance.interceptors.request.use(
			function (config) {
				// Do something before request is sent
				console.log('axios config', config);
				let url = config.url;
				let params = queryString.parseUrl(url);
				let newUrl;
				if (Object.keys(params.query).length > 0) {
					let newParams = { ...params.query, cinema_id };
					newUrl = queryString.stringifyUrl({ url: params.url, query: newParams });
				} else {
					newUrl = queryString.stringifyUrl({ url: params.url, query: { cinema_id } });
				}
				config.url = newUrl;
				return config;
			},
			function (error) {
				// Do something with request error
				return Promise.reject(error);
			}
		);
	}

	instance.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
	return instance;
};
