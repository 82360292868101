import React, { useEffect, useState, useContext, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { setFinalTicketDetails } from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import creditcard from '../assets/imgs/svgs/credit-card.svg';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { PaymentService } from '@apiService/PaymentService';
import BookingService from '@apiService/BookingService';

const tempResp = {
	EventName: 'TERMINAL_RESPONSE',
	JsonResult: {
		TransactionResponseEnglish: 'APPROVED',
		PrimaryAccountNumber: '476173******0010',
		TransactionAmount: '100.00',
		TransactionSTAN: '000127',
		TransactionDateTime: '191210212444',
		CardExpiryDate: '12/18',
		RetrievalReferenceNumber: '934421240035',
		TransactionAuthCode: '010000',
		TransactionResponse: '000',
		CardAcceptorTerminalId: '1234567812345678',
		CardAcceptorName: '5031|P1–SPAN|mada| ﻣﺪى',
		CardAcceptorIdCode: '650999000911000',
		AdditionalAmount: '0.00',
		BankId: 'RAJB',
		CardAcceptorBusinessCode: '3010',
		DateTime: '20191210212444|20191210212447',
		EMVTagsIssuer:
			'Visa Debit SPAN|DIPPED|A0000002282010|08C824E000|E800|40|420300|5431C3B830C48C06|2',
		TransactionType: '1',
		MerchantInfo: '7.23|mPOS||ﻧﻗطﺔاﻟﺑﯾﻊاﻟﻣﺣﻣوﻟ gniKﺔ Saud Street| ﺸﺎرعاﻟﻣﻟكﺳﻌود',
		ResponseCodeDescription: 'ﻣﻗﺑوﺔﻟ|APPROVED',
		TerminalModel: 'Spectra T1000',
		ECRReferenceNumber: '0123456789012345',
		TransactionResponseArabic: 'ﻣﻗﺑوﺔﻟ',
		TransactionTypeAsReadable: 'PURCHASE',
		TerminalVersion: '7.23',
		MerchantNameEnglish: 'mPOS',
		MerchantNameArabic: '',
		'ﻧﻗطﺔاﻟﺑﯾﻊاﻟﻣﺣﻣوﻟ gniK': 'hsilgnE1sserddAtnahcreMﺔ SaudStreet',
		MerchantAddress1Arabic: 'ﺸﺎرعاﻟﻣﻟكﺳﻌود',
		MerchantAddress2English: '',
		MerchantAddress2Arabic: '',
		TransacitonRequestDateTime: '20191210212444',
		TransactionResponseDateTime: '20191210212447',
		CardScheme: 'Visa Debit SPAN',
		POSEntryMode: 'DIPPED',
		AID: 'A0000002282010',
		TVR: '08C824E000',
		TSI: 'E800',
		CryptResult: '40',
		CVMResult: '420300',
		ApplicationCryptogram: '5431C3B830C48C06',
		CardHolderVerificationMethod: '2',
		CardHolderVerificationMethodAsReadable: 'Card Holder Verified by PIN.',
		CardCode: 'P1–SPAN',
		CardNameEnglish: 'mada',
		CardNameArabic: '',
		TransactionResultReasonArabic: 'Arabic approved',
		TransactionResultReasonEnglish: 'APPROVED',
		Event: 'OnDataReceive',
	},
};

function KioskCollectTwo({ t }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { arabic } = useContext(globalConfigContext);

	const [message, setMessage] = useState('');
	const [terminalAction, setTerminalAction] = useState(null);
	const [isBookingInProcess, setIsBookingInProcess] = useState(false);
	const [isBookingSuccessful, setIsBookingSuccessful] = useState(null);
	const [isPaymentDone, setIsPaymentDone] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [doDisconnect, setDoDisconnect] = useState(false);




	console.log(terminalAction, "terminalAction >>>>>>>>>>>>>>>>>>>")
	console.log("is conect >>>>>>>>>>" , isConnected)
	console.log("is payment done >>>>>>>>>>" , isPaymentDone)

	const selectedTicketPrice = useSelector((state) => state.booking.selectedTicketPrice);
	const fnbPrice = useSelector((state) => state.foodAndBeverages.fnb_price);
	const modifierPrice = useSelector((state) => state.foodAndBeverages.modifier_price);
	const bookingFee = useSelector((state) => state.booking.bookingFee);

	const reservation_id = useSelector((state) => state.booking.reservation_id);
	const email = useSelector((state) => state.booking.userDetails.email);
	const mobile = useSelector((state) => state.booking.userDetails.mobile);
	const amount =
		(selectedTicketPrice.priceInCents +
			fnbPrice.priceInCents +
			modifierPrice.priceInCents +
			bookingFee) /
		100;
	const totalFnbPrice = fnbPrice.priceInCents + modifierPrice.priceInCents;

	const redirectToFinalTicket = () => {
		history.push(`/checkout`);
	};

	// this function is called after the payment is successful
	const bookSeats = async (
		card_number,
		payment_option,
		expiry_date,
		RetrievalReferenceNumber,
		TransactionAuthCode,
		ECRReferenceNumber
	) => {
		try {
			const response = await BookingService.BookSeats({
				email,
				mobile,
				reservation_id,
				card_number,
				amount: amount.toString(),
				payment_option,
				expiry_date,
				fnb_price: totalFnbPrice,
				language: arabic ? 'ar' : 'en',
				RetrievalReferenceNumber,
				TransactionAuthCode,
				ECRReferenceNumber,
			});
			if (response && response.status === 200) {
				return response.data.data.details;
			}
		} catch (err) {
			console.log(err);
			throw err;
		}
	};

	const onPaymentSuccessful = async (response) => {
		setIsBookingInProcess(true);
		try {
			const {
				PrimaryAccountNumber, // 476173******0010
				TransactionDateTime, // 191210212444
				CardExpiryDate, // "12/18"
				RetrievalReferenceNumber, //934421240035
				CardAcceptorName, // "5031|P1–SPAN|mada| ﻣﺪى"
				BankId, //RAJB
				TransactionTypeAsReadable, // PURCHASE
				CardScheme, // Visa Debit SPAN
				CardNameEnglish, // mada
				TransactionAuthCode,
				ECRReferenceNumber,
			} = response;
			const transactionDetails = await bookSeats(
				PrimaryAccountNumber,
				CardNameEnglish,
				CardExpiryDate,
				RetrievalReferenceNumber,
				TransactionAuthCode,
				ECRReferenceNumber
			);
			dispatch(setFinalTicketDetails({ ...response, ...transactionDetails }));
			setIsBookingSuccessful(true);
			redirectToFinalTicket();
		} catch (err) {
			console.log(err);
			setIsBookingSuccessful(false);
		}
		setIsBookingInProcess(false);

		// var a = window.open('');
		// a.document.write(response.HtmlReceiptForCustomer);
		// a.document.close();
		// a.print();
		PaymentService.InitiateReconciliation();
	};

	useEffect(() => {

		console.log("inside the payment")

		PaymentService.addMessageListener(function (event) {
			console.log(event.data);
			const message = JSON.parse(event.data);
			console.log(message, 'message');
			if (message.IsConnected === 'True') {
				setIsConnected(true);
				console.log('message.IsConnected :>> ', message.IsConnected);
				const totalAmount = amount.toString(); //divide by 100 if needed and pass totalAmount into initPurchaseTransaction
				//PaymentService.initPurchaseTransaction('1.00');

				setTimeout(() => {
					console.log('Is it Connect');
					PaymentService.initPurchaseTransaction(totalAmount);
				}, 9000);

				// if (doDisconnect == false) {
				// 	PaymentService.handleCOMDisconnect();
				// 	PaymentService.handleCOMConnect();
				// }
				// setDoDisconnect(true);
			} else if (message.EventName === 'TERMINAL_ACTION') {
				const JsonResponse = JSON.parse(message.JsonResult);
				console.log('JsonResponse :>> ', JsonResponse, '==', JsonResponse.TerminalAction);
				setTerminalAction(JsonResponse.TerminalAction);
				setMessage(JsonResponse.OptionalMessage);
			} else if (message.EventName === 'TERMINAL_RESPONSE') {
				setIsPaymentDone(true);
				const response = JSON.parse(message.JsonResult);
				console.log('Success response :>> ', response);
				if (response.TransactionResponseEnglish === 'APPROVED') {
					console.log('transaction approved');
					onPaymentSuccessful(response);
					// var a = window.open('');
					// a.document.write(response.HtmlReceiptForCustomer);
					// a.document.close();
					// a.print();
					PaymentService.InitiateReconciliation();
				}
			}
		});

		console.log('coming here');
		PaymentService.handleCOMConnect();
		PaymentService.GetSettings();

		// onPaymentSuccessful(tempResp.JsonResult);

		return () => PaymentService.removeMessageListener();
	}, []);

	return (
		<>
			{terminalAction === 'CARD_INSERTED' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'PIN_ENTRY' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'CONNECTING' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'REMOVE_CARD' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'IDLE_SCREEN' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : terminalAction === 'WAITING_RESPONSE' ? (
				<img className='text-center' src={require('../assets/transction-image/payment-terminal.gif')} />
			) : null}
			{/* <span>{message}</span> */}

			<MovieHeader />

			{/* {!isPaymentDone && (
				<div className="kumbh">
					<div className="middle-section">
						<h1 className="fw-bold pb-5 text-center">{t('process_payment.Processing Payment')}</h1>
						<img src={creditcard} className="img-center" />
					</div>
				</div>
			)} */}

			{!isConnected && (
				<div className="kumbh">
					<div className="middle-section">
						<h1 className="fw-bold pb-5 text-center">
							{t('process_payment.Not Connected To Payment Terminal')}
						</h1>
					</div>
				</div>
			)}

			{isConnected && (
				<>
					{!isPaymentDone && (
						<div className="kumbh">
							<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Please Complete the Payment With Your Card')}
								</h1>
							</div>
						</div>
					)}

					
					{isPaymentDone && !isBookingInProcess && isBookingSuccessful === null && (
						<div className="kumbh">
							<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{/* {t('process_payment.Please Complete the Payment With Your Card')} */}
									Plseae Make Your Payment with your card
								</h1>
							</div>
						</div>
					)}

					{isBookingInProcess && isBookingSuccessful === null && isPaymentDone && (
						<div className="kumbh">
							<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Payment Sucessful')}
								</h1>
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Booking In Process')}
								</h1>
							</div>
						</div>
					)}
					{isBookingSuccessful === true && (
						<div className="kumbh">
							<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Payment Sucessful')}
								</h1>
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Booking Sucessful')}
								</h1>
								{/* <button className="blue-btn btn-lg px-5">
							{t('checkout.PRINT TICKET')}
						</button> */}
							</div>
						</div>
					)}
					{isBookingSuccessful === false && (
						<div className="kumbh">
							<div className="middle-section">
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Payment Sucessful')}
								</h1>
								<h1 className="fw-bold pb-5 text-center">
									{t('process_payment.Booking Not Successful. Your money will be refunded')}
								</h1>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default withNamespaces()(KioskCollectTwo);
