import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	fetchSeatLayoutData,
	fetchTicketPrice,
	selectSeats,
	vistaSetSeats,
	clearBookingState,
	clearFoodAndBeveragesState,
	cancelTransaction,
	stopTimer,
	selectOfferSeats
} from '@store/booking/bookingActions';
import { validateVoucher } from '@store/booking/bookingActions';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { timeConvert, isImageURLNull, translateSessionAttribute } from '@helper/formatting.js';
import { Container, Row, Col, Table, Alert, Modal, Spinner,  
	Dropdown,
	Accordion,
	Card,
	Form
} from 'react-bootstrap';
import MovieHeader from '@components/partials/MovieHeader';
import CSpinner from '@components/partials/Spinner';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import whiteSofa from '../assets/imgs/svgs/sofa.svg';
import wheelChair from '../assets/imgs/svgs/wheel-chair.svg';
import selectedSeat from '../assets/imgs/svgs/seat-sl-selected.svg';
import occupiedSeat from '../assets/imgs/svgs/seat-sl-occupied.svg';
import whiteSeat from '../assets/imgs/svgs/seat-sl.svg';

/* Ratings Images */
import GRatingImg from "../assets/imgs/ratings/G.png";
import PGRatingImg from "../assets/imgs/ratings/PG.png";
import PG12RatingImg from "../assets/imgs/ratings/PG12.png";
import R12RatingImg from "../assets/imgs/ratings/R12.png";
import R15RatingImg from "../assets/imgs/ratings/R15.png";
import R18RatingImg from "../assets/imgs/ratings/R18.png";

import GenericService from "@apiService/GenericService";

import {
	titleCase,
	// translateSessionAttribute,
	// formatYuppErrors,
  } from "@helper/formatting";


let ratingToImageMapping = {
	G: GRatingImg,
	PG: PGRatingImg,
	PG12: PG12RatingImg,
	R12: R12RatingImg,
	R15: R15RatingImg,
	R18: R18RatingImg,
  };
  

const els3 = [1, 2, 3];
const els10 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const el16 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
// const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

let showPno = (e) => {
	const pno = document.querySelector('#pno');
	const bankOffers = document.querySelector('#bankOffers');
	const pnoBtn = document.querySelector('#pnoBtn');
	const bankOffersBtn = document.querySelector('#bankOffersBtn');

	if (pno.classList.contains('d-none')) {
		pno.classList.add('d-flex');
		bankOffers.classList.remove('d-none');
		bankOffers.classList.add('d-none');
		bankOffers.classList.remove('d-flex');
	}
	if (!e.target.classList.contains('active')) {
		e.target.classList.add('active');
		bankOffersBtn.classList.remove('active');
	}
};

let showBankOffers = (e) => {
	const pno = document.querySelector('#pno');
	const bankOffers = document.querySelector('#bankOffers');
	const pnoBtn = document.querySelector('#pnoBtn');
	const bankOffersBtn = document.querySelector('#bankOffersBtn');

	if (bankOffers.classList.contains('d-none')) {
		pno.classList.add('d-none');
		bankOffers.classList.add('d-flex');
		pno.classList.remove('d-flex');
		bankOffers.classList.remove('d-none');
	}
	if (!e.target.classList.contains('active')) {
		e.target.classList.add('active');
		pnoBtn.classList.remove('active');
	}
};

const getSeatClass = (status, singleSeat) => {
	if (singleSeat.coupleSeat) {
		return 'sofa';
	} else if (status === 3) {
		return 'wheel-chair';
	} else return 'cell';
};

function SeatLayout(props) {
	const { arabic, currency, country_data } = useContext(globalConfigContext);

	const { session_id } = props.match.params;
	const { cinema_id } = props.match.params;
	const t = props.t;

	const [showSpinner, setShowSpinner] = useState(false);

	const reservation_id = useSelector((state) => state.booking.reservation_id);

	const dispatch = useDispatch();

	useEffect(() => {
		window.scrollTo(0, 0);
		setShowSpinner(true);

		if (reservation_id) {
			dispatch(cancelTransaction({ reservation_id }));
		}

		dispatch(clearBookingState());
		dispatch(clearFoodAndBeveragesState());
		dispatch(stopTimer());

		Promise.allSettled([
			dispatch(fetchSeatLayoutData({ session_id, cinema_id })),
			dispatch(fetchTicketPrice({ session_id, cinema_id })),
		]).finally(() => setShowSpinner(false));
	}, [session_id, cinema_id, dispatch]);

	// const [seats, setSeats] = useState([]);
	const seats = useSelector((state) => state.booking.seats_selected);
	const seatLayout = useSelector((state) => state.booking.seat_layout);
	const selectedTicketPrice = useSelector((state) => state.booking.selectedTicketPrice);
	const movieDetails = useSelector((state) => state.movies.movie_details);
	const showDetails = useSelector((state) =>
		state.booking.show_details && state.booking.show_details.length > 0
			? state.booking.show_details[0]
			: {}
	);
	const confirmedBankOfferTicketObj = useSelector(
		(state) => state.booking.selectedBankOfferTicket
	  );
	const selected_seats = useSelector((state) => state.booking.vista_seats_selected);
	const seatSelectError = useSelector((state) => state.booking.seat_select_error);

	const [showSeatLimitError, setShowSeatLimitError] = useState(false);
	const [showProceedSpinner, setShowProceedSpinner] = useState(false);
	const [showVoucherPopup, setShowVoucherPopup] = useState(false);
	const [voucherCode, setVoucherCode] = useState(null);
	const [voucherCodeLoading, setVoucherCodeLoading] = useState(false);
	const [voucherSeatsExceededError, setVoucherSeatsExceededError] = useState(false);
	const [cities, setCities] = useState(null);

	const [selectedCategoryOffer, setSelectedCategoryOffer] = useState([]);
	const [updatePrice, setUpdatePrice] = useState(false);

	const [showCovidDisclaimerModal, setShowCovidDisclaimerModal] =
    useState(false);

	const [isProceedEnabled, setIsProceedEnabled] = useState(false);

	console.log(updatePrice, "LLLLLLLLLLLL")

	const [selectedBankOfferTicketObj, setSelectedBankOfferTicketObj] =
    useState(false);

	const [toShow, setToShow] = useState(false);

	const voucherCodeData = useSelector((state) => state.booking.voucherCodeData);
	const voucherCodeError = useSelector((state) => state.booking.voucherCodeError);
	const bookingFee = useSelector((state) => state.booking.bookingFee);
	const bookingFeeTax = useSelector((state) => state.booking.bookingFeeTax);

	const [orderOpen, setOrderOpen] = useState([0, 0, 0]);
  const toggleHandle = (key, value = "closeCollapse") => {
    setOrderOpen((oldState) => {
      let openCopy = Array(3).fill(0);
      if (value == "openCollapse") {
        openCopy[key] = 1;
      } else {
        openCopy[key] = !oldState[key];
      }
      return openCopy;
    });
  };

  useEffect(()=>{
	GenericService.GetCities().then((response) => {
		if (response && response.data) {
		  setCities(response.data.data);
		}
	  });

	  setShowCovidDisclaimerModal(false);
  }, [])

  if (arabic) {
    var span = (
      <ul>
        <li className="mb-3">
          أؤكد أنني أبلغ من العمر 12 عامًا أو أكثر ، وقد حدثت جرعة التطعيم
          الثانية في فترة في قبل 14 يومًا على الأقل من تاريخ اليوم أو تعافيت ،
          وهذا شهادة التطعيم ستكون موجودة من أجل التفتيش عليها دخولي إلى
          السينما.
        </li>
        <li className="mb-3">
          أؤكد بموجب هذا أنني لن أتلقى استردادًا نقديًا أو رصيدًا أو تذكرة معدلة
          لتذكرة السينما التي اشتريتها إذا لم تكن شهادة التطعيم الخاصة بي موجودة
          عند دخولي إلى السينما.
        </li>
      </ul>
    );
  }
  if (!arabic) {
    var span = (
      <ul>
        {/* <li className="mb-3">
          I confirm that I am 12 years or older, my second vaccination dose
          occurred at a period of at least 14 days prior to today's date or I
          have recovered, and that my vaccination certificate will be present in
          order for inspection upon my entry to the cinema.
        </li> */}
        <li className="mb-3">
          I hereby confirm that I will not receive a cash refund, credit or
          amended ticket for my purchased cinema ticket if my vaccination
          certificate is not present when I enter the cinema
        </li>
      </ul>
    );
  }
  if (arabic) {
    var spanKSA = (
      <ul>
        {/* <li className="mb-3">
          - أؤكد انني ومرافقيني تلقينا الجرعتين و تظهر حالتنا في تطبيق توكلنا
          على اننا محصنين وأوافق على تقديم الحالة الصحية عند الدخول إلى السينما.
        </li> */}
        <li className="mb-3">
          - أؤكد بموجب هذا أنني لن اتلقى استرداداً نقدياً أو رصيداً أو تذكرة
          مؤجلة إذا لم تكن حالتي الصحية في تطبيق توكلنا "محصن" عند دخولي إلى
          السينما
        </li>
      </ul>
    );
  }
  if (!arabic) {
    var spanKSA = (
      <ul>
        <li className="mb-3">
          I confirm i have had two vaccination doses and agree to provide
          TAWAKLNA app upon entry to cinema, if required.
        </li>
        <li className="mb-3">
          I hereby confirm that i will not receive a cash refund, credit, or
          amended ticket for my purchase if TAWAKLNA status is not immuned, and
          I didn't present my TAWAKLNA when i enter the cinema.
        </li>
      </ul>
    );
  }

  if (arabic) {
    var spanOM = (
      <ul>
        <li className="mb-3">
          - أؤكد انني ومرافقيني تلقينا جرعة تطعيم واحدة على الأقل و تظهر حالتنا
          في تطبيق توكلنا على اننا محصنين وأوافق على تقديم الحالة الصحية عند
          الدخول إلى السينما
        </li>
        <li className="mb-3">
          - أؤكد بموجب هذا أنني لن اتلقى استرداداً نقدياً أو رصيداً أو تذكرة
          مؤجلة إذا لم تكن حالتي الصحية في تطبيق توكلنا "محصن" عند دخولي إلى
          السينما{" "}
        </li>
      </ul>
    );
  }
  if (!arabic) {
    var spanOM = (
      <ul>
        <li className="mb-3">
          I confirm I have had at least one vaccination dose and agree provide
          my vaccination certificate upon entry to the cinema, if required.
        </li>
        <li className="mb-3">
          I hereby confirm that I will not receive a cash refund, credit, or
          amended ticket for my purchase if my vaccination certificate is not
          present when I enter the cinema.
        </li>
      </ul>
    );
  }

  const onProcess = async () => {
    if (
      country_data.country_short_code == "BH" ||
      country_data.country_short_code == "SA" ||
      country_data.country_short_code == "OM"
    ) {
      setToShow(true);
    } else {
      onProceed();
    }
  };
  

  const onSelectTicketTypes = (type, index, areaCode) => {

	console.log(type, "PPPPPPPPPPPPPPPPPPP")
    if (seatLayout && seatLayout.length > 0) {
      seatLayout[index]["selectedCatOffer"] = type.DisplayName != "None" ? type.DisplayName : type.Description;
    }
    let existingTypes = selectedCategoryOffer.filter((p) => {
      return p.AreaCategoryCode == type.AreaCategoryCode;
    });

    if (existingTypes.length > 0 && selectedCategoryOffer.length > 0) {
      selectedCategoryOffer.splice(0, 1);
    }

    setSelectedCategoryOffer([...selectedCategoryOffer, type]);

    // let finalPriceInCents = 0;
    // let finalTaxInCents = 0;
    // const priceWithTax = type.PriceInCents;
    // const priceWithoutTax = parseFloat(
    //   (priceWithTax / (1 + taxRate)).toFixed(2)
    // );
    // const tax = parseFloat((priceWithTax - priceWithoutTax).toFixed(2));
    // console.log(type, "type");
    // // if (areaCode) {
    // //   let alreadySelectedArea = selected_seats.filter((m) => {
    // //     return (
    // //       m.AreaCategoryCode == areaCode.AreaCategoryCode &&
    // //       m.TicketTypeCode != areaCode.TicketTypeCode
    // //     );
    // //   });
    // //   console.log(alreadySelectedArea, "kjhgj");
    // //   if (alreadySelectedArea.length > 0) {
    // //     selected_seats.map((item) => {
    // //       console.log(item.AreaCategoryCode, item.TicketTypeCode);
    // //       if (
    // //         item.AreaCategoryCode == areaCode.AreaCategoryCode &&
    // //         item.TicketTypeCode == areaCode.TicketTypeCode
    // //       ) {
    // //       }
    // //       // AreaCategoryCode: "0000000001"
    // //       // AreaNumber: 1
    // //       // ColumnIndex: 3
    // //       // RowIndex: 7
    // //       // TicketTypeCode: "0001"
    // //       // coupleSeat: false
    // //       // dummyTaxInCents: 573.91
    // //       // isBankOffer: false
    // //       // isBogo: false
    // //       // isVoucher: false
    // //       // priceInCents: 4400
    // //       // seatDescription: "PREMIUM"
    // //       // seatNameDescription: "C-11"
    // //       // ticketTypeCodeDescription: "PREMIUM"
    // //     });
    // //   }
    // //   // console.log(type, "hhh");
    // // }
    // console.log(selected_seats, "dispatch(clearSelectedSeats());");
    // if (type) {
    //   let filterSeatTypes = selected_seats.filter((p) => {
    //     return p.AreaCategoryCode == type.AreaCategoryCode;
    //   });
    //   if (filterSeatTypes.length > 0) {
    //     filterSeatTypes.map((item, indexSeat) => {
    //       if (item.coupleSeat == true && item.priceInCents == 0) {
    //         // console.log(
    //         //   (item[index + 1].TicketTypeCode = type.TicketTypeCode),
    //         //   "couple"
    //         // );
    //         item.priceInCents = 0;
    //       } else {
    //         item.priceInCents = type.PriceInCents;
    //       }
    //       item.TicketTypeCode = type.TicketTypeCode;
    //       if (item.dummyTaxInCents) {
    //         item.dummyTaxInCents = tax;
    //       }
    //       item.ticketTypeCodeDescription = type.Description;
    //     });
    //   }
    //   console.log(filterSeatTypes, "filterSeatTypes");
    // }
    // for (let i = 0; i < selected_seats.length; i++) {
    //   finalPriceInCents += selected_seats[i].priceInCents;
    //   if (selected_seats[i].dummyTaxInCents) {
    //     finalTaxInCents += selected_seats[i].dummyTaxInCents;
    //   }
    // }
    // console.log(finalTaxInCents);
    // console.log(finalPriceInCents, "==", finalTaxInCents);
    // selectedTicketPrice.priceInCents = finalPriceInCents;
    // selectedTicketPrice.originalPriceInCents = finalPriceInCents;
    // selectedTicketPrice.taxInCents = finalTaxInCents;
    // selectedTicketPrice.originalTaxInCents = finalTaxInCents;
    // selectedTicketPrice.totalWithFnb = finalPriceInCents;
    // selectedTicketPrice.originalTotalWithFnb = finalPriceInCents;
    // console.log(selectedTicketPrice, type, selected_seats, "hutyutuy");
    // if (updatePrice == false) {
    //   setUpdatePrice(true);
    // } else {
    //   setUpdatePrice(false);
    // }
  };

	const checkSeatValid = (seatStatus, seatIndex, rowIndex, seatTypes, singleSeat) => {
		if (seatStatus == 1 || seatStatus == 4 || seatStatus == 5) {
			return false;
		} else if (voucherCodeData) {
			let ticketTypeExists = voucherCodeData.ticketTypes.findIndex((obj) => {
				return (
					seatTypes.TicketTypeCodeBarcode &&
					seatTypes.TicketTypeCodeBarcode.includes(obj.ticketTypeCode)
				);
			});
			if (ticketTypeExists === -1) {
				return false;
			} else {
				return true;
			}
		}
		return true;
	};

	const voucherHandler = async () => {
		if (voucherCodeLoading) {
			return;
		}
		if (voucherCode) {
			setVoucherCodeLoading(true);
			dispatch(
				validateVoucher({
					voucher_code: voucherCode,
					cinema_id,
					session_id,
				})
			)
				.then((data) => (data !== 'INVALID' ? setShowVoucherPopup(false) : null))
				.finally(() => setVoucherCodeLoading(false));
		}
	};


	useEffect(() => {
		if (selectedCategoryOffer.length >= 0) {

			console.log(selectedCategoryOffer, "UUUUUUUUUUUUUUUUUUUUUUUUUUUUU")
		  dispatch(
			selectOfferSeats({
			  selectedCategoryOffer,
			})
		  );
		  if (updatePrice == false) {
			setUpdatePrice(true);
		  } else {
			setUpdatePrice(false);
		  }
		}
	
		// if(voucherCodeData) {
		//   if((voucherCodeData.isBogo && seats.length < voucherCodeData.numberOfRedemptionsRemaining * 2)
		//   || (!voucherCodeData.isBogo && seats.length < voucherCodeData.numberOfRedemptionsRemaining))
		//     setVoucherSeatsExceededError(false);
		// }
	  }, [selectedCategoryOffer]);

	useEffect(() => {
		if (seats && seats.length < 6) {
			setShowSeatLimitError(false);
		}
	}, [seats, voucherCodeData]);

	const onSelectSeats = (
		seatTypeIndex,
		rowIndex,
		seatIndex,
		seatTypes,
		singleSeat,
		adjacentSeatPosition
	) => {
		console.log(singleSeat);
		const { Position, Status } = singleSeat;
		if (Status == 1 || !checkSeatValid(Status, seatIndex, rowIndex, seatTypes, singleSeat)) return;

		let seatName =
			seatLayout[seatTypeIndex].Rows[rowIndex].PhysicalName +
			'-' +
			seatLayout[seatTypeIndex].Rows[rowIndex].Seats[seatIndex].Id;

		if (seats.length === 6 && !seats.includes(seatName)) {
			setShowSeatLimitError(true);
		}

		dispatch(
			selectSeats({
				seatTypeIndex,
				rowIndex,
				seatIndex,
				Position,
				singleSeat,
				adjacentSeatPosition,
			})
		);

		if (selectedBankOfferTicketObj == false && !voucherCodeData) {
			let initailOption = selectedCategoryOffer.filter((x) => {
			  return x.AreaCategoryCode == Position.AreaCategoryCode;
			});
			//  let existingTypes = selectedCategoryOffer.filter((p) => {
			//       return p.AreaCategoryCode == type.AreaCategoryCode;
			//     });
	  
			//     if (existingTypes.length > 0 && selectedCategoryOffer.length > 0) {
			//       selectedCategoryOffer.splice(0, 1);
			//     }
			if (initailOption.length <= 0 && selectedBankOfferTicketObj == false) {
			  if (seatLayout && seatLayout.length > 0) {
				let obj = {
				  AreaCategoryCode: seatLayout[seatTypeIndex].AreaCategoryCode,
				  Description: seatLayout[seatTypeIndex].Description,
				  PackageContent: { Concessions: [], Tickets: [] },
				  PriceInCents: seatLayout[seatTypeIndex].PriceInCents,
				  TaxInCents: seatLayout[seatTypeIndex].TaxInCents,
				  TicketTypeCode: seatLayout[seatTypeIndex].TicketTypeCode,
				};
				setSelectedCategoryOffer([...selectedCategoryOffer, obj]);
				seatLayout[seatTypeIndex].cinepolisOffersPrices.push(obj);
			  }
			}
	  
			dispatch(
			  selectOfferSeats({
				selectedCategoryOffer,
			  })
			);
		  }
	};



	const redirectToFnb = () => {

		history.push(`/fnb-confirm/${cinema_id}/${session_id}`);

		// if (showDetails.cinema_is_fnb === 'N') {
		// 	history.push(`/fnb-confirm/${cinema_id}/${session_id}`);
		// } else {
		// 	props.history.push(`/fnb/${cinema_id}/${session_id}`);
		// }
	};

	const onProceed = async () => {
		if (
			selected_seats.length === 0 ||
			showProceedSpinner ||
			(voucherCodeData &&
				voucherCodeData.isBogo &&
				selected_seats.length < voucherCodeData.numberOfRedemptionsRemaining * 2)
		) {
			return;
		}

		let payload = {
			cinema_id,
			seats_name: seats.toString(),
			user_selected_seats: selected_seats,
			session_id,
			selectedTicketPrice,
			voucher_data: voucherCodeData,
		};
		setShowProceedSpinner(true);
		await dispatch(vistaSetSeats(payload, redirectToFnb));
		setShowProceedSpinner(false);
	};

	const history = useHistory();
	const onClickBack = () => {
		history.go(-1);
	};

	return (
		<div className="">

<Modal
        show={toShow}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="disclaimer-popup"
        onHide={() => setToShow(false)}
      >
        <Modal.Header
          className="justify-content-center align-items-center mt-4 mb-3"
          closeButton={true}
        >
          <p className="font-weight-bold align-center fz">
            {t("seatlayout_page.covid-disclaimer-popup.title")}
          </p>
        </Modal.Header>
        <Modal.Body className="text-left pl-0">
          {/* {country_data.country_short_code == "BH"
            ? span
            : country_data.country_short_code == "SA"
            ? spanKSA
            : country_data.country_short_code == "OM"
            ? spanOM
            : ""} */}
        </Modal.Body>
        <Container fluid className="bg-light-black brds-8 my-4">
          <Row className="">
            <Col xs={12} className="">
              <div className="d-flex justify-content-between align-items-center">
                <div className={`d-flex text-light frs-16`}>
                  <div className="mod-form">
                    <Form.Check
                      className="mod-form"
                      onChange={(e) => setIsProceedEnabled(e.target.checked)}
                      checked={isProceedEnabled}
                      type="checkbox"
                      id="tnc1-checkbox"
                      label={
                        <>
                          {t("checkout_page.generic-tnc")}{" "}
                          <a
                            href={window.location.origin + "/tnc"}
                            target="_blank"
                          >
                            {t("checkout_page.terms and conditions")}
                          </a>
                        </>
                      }
                    />
                  </div>
                </div>
                <div className="ml-3">
                  <a
                    href="#"
                    className={`small blue-btn text-nowrap px-3 ${
                      !isProceedEnabled ? "inactive-btn" : ""
                    }`}
                    onClick={() => {
                      if (isProceedEnabled) {
                        setToShow(false);
                        onProceed();
                      }
                    }}
                  >
                    AGREE
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal>
			<CSpinner active={showSpinner} />
			<MovieHeader />
			<Modal
          show={showCovidDisclaimerModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
          className="disclaimer-popup"
          size="md"
          backdrop="static"
          onHide={()=>setShowCovidDisclaimerModal(false)}
        >
          <Modal.Header className="position-relative" closeButto>
            {" "}
            {/* temporarily removed closeButton */}
            {/* <p className="back cursor-pointer fw-reg" onClick={() => history.go(-1)} >
              <img src={arabic ? rightArrow : leftArrow} alt="arrow" className={`icon-18 ${arabic ? "ml-2" : "mr-2"}`} />
              {t('common.Back')}
            </p> */}
            <h2 className="title">
              {t(
                "seatlayout_page.covid-disclaimer-popup.important-information"
              )}
            </h2>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="message border-grey-bottom">
              <p className="=">
                {t("seatlayout_page.covid-disclaimer-popup.subtitle")}
              </p>
            </div> */}

            {/* {country_data && country_data.country_short_code === "BH" && (
              <div className="message border-grey-bottom">
                <p className="">
                  {t("seatlayout_page.covid-disclaimer-popup.bh-green-sheild")}
                </p>
              </div>
            )} */}

            {showDetails &&
              showDetails.SessionAttribute &&
              showDetails.SessionAttribute.toLowerCase() === "junior" && (
                <div className="message border-grey-bottom">
                  <p className="">
                    {t("seatlayout_page.covid-disclaimer-popup.junior-message")}
                  </p>
                </div>
              )}

            <div className="message border-grey-bottom py-1">
              <p className="">
                {t(
                  "seatlayout_page.covid-disclaimer-popup.fnb-from-outside-not-allowed"
                )}
              </p>
            </div>

            {showDetails &&
              showDetails.rating_tnc &&
              showDetails.rating_tnc[0] && (
                <div className="message border-grey-bottom py-3">
                  {/* <h3 className="title mt-0 mb-1">
                    {t("seatlayout_page.covid-disclaimer-popup.rules-of-entry")}
                  </h3> */}
                  <div className="d-flex align-items-center justify-content-center">
                    {ratingToImageMapping[showDetails.rating] && (
                      <img
                        src={ratingToImageMapping[showDetails.rating]}
                        height="70"
                        width="70"
                        className="mr-3"
                      />
                    )}
                    <label for="tnc3" className="mt-n1 mb-0 text-center">
                      <strong className="frs-18">
                        {showDetails && showDetails.rating}
                      </strong>{" "}
                      :{" "}
                      {arabic
                        ? showDetails && showDetails.rating_tnc[0].tnc_ar
                        : showDetails && showDetails.rating_tnc[0].tnc}
                    </label>
                  </div>
                </div>
              )}

            {cities && showDetails && (
              <div className="message my-1">
                <p
                  className={`showtime-popup-msg ${
                    arabic ? "text-right" : "text-left"
                  }`}
                >
                  <strong>{t("common.cinema")}</strong>:{" "}
                  {arabic
                    ? showDetails?.cinema_name_ar
                    :showDetails?.cinema_name}{" "}
                  <br />
                  <strong>{t("common.city")}</strong>:{" "}
                  {arabic
                    ? cities?.find(
                        (city) => city.city_id === showDetails?.city_id
                      )?.city_name_ar
                    : cities?.find(
                        (city) => city.city_id === showDetails?.city_id
                      )?.city_name}{" "}
                  <br />
                  <strong>{t("common.showdate")}</strong>:{" "}
                  {moment.utc(showDetails?.Showtime).format("DD-MM-YYYY")}{" "}
                  <br />
                  <strong>{t("common.showtime")}</strong>:{" "}
                  {moment.utc(showDetails?.Showtime).format("hh:mm A")}
                  <br />
                  <p className={`mb-0 pb-0 text-center ${arabic ? "" : ""}`}>
                    {t("movie_page.seats-cannot-be-swapped-later")}
                  </p>
                </p>
              </div>
            )}

            <div className="check-bx">
              {/* <div className="d-flex pb-3">
                <input id="tnc2" type="checkbox" className="mr-3" checked={covidTnc1} onChange={e => setCovidTnc1(e.target.checked)} />
                <label for="tnc2" className="mt-n1">{t('seatlayout_page.covid-disclaimer-popup.tnc1')}</label>
              </div>
              <div className="d-flex pb-3">
                <input id="tnc2" type="checkbox" className="mr-3" checked={covidTnc2} onChange={e => setCovidTnc2(e.target.checked)} />
                <label for="tnc2" className="mt-n1">{t('seatlayout_page.covid-disclaimer-popup.tnc2')}</label>
              </div> */}
              {/* {(showDetails && showDetails.rating_tnc && showDetails.rating_tnc[0]) && <div className="d-flex pb-3">
                <input id="tnc3" type="checkbox" className="mr-3" checked={covidTnc3} onChange={e => setCovidTnc3(e.target.checked)} />
                <label for="tnc3" className="mt-n1">{showDetails && showDetails.rating} - {arabic ? (showDetails && showDetails.rating_tnc[0].tnc_ar) : (showDetails && showDetails.rating_tnc[0].tnc)}</label>
              </div>} */}
              {/* {(covidDisclaimerErrors && (covidDisclaimerErrors.covidTnc1 || covidDisclaimerErrors.covidTnc2 || covidDisclaimerErrors.covidTnc3)) &&
                <span className="form-error-text d-block text-align-center">{t('seatlayout_page.errors.accept-tnc')}</span>
              } */}
            </div>

            <button
              className={`btn blue-btn px-5 mt-3 btn-center`}
              onClick={()=>setShowCovidDisclaimerModal(false)}
            >
              {t("common.CONFIRM")}
            </button>
          </Modal.Body>
        </Modal>
			<div className="bg-light-black">
				<Container fluid>
					<div>
						<Row className="align-items-center py-3 mb-40">
							<Col>
								<div onClick={onClickBack} className="go_back">
									<div className="button_wrap">
										<div className="go_back_button"></div>
									</div>
									<h2 className="big_text">{t('GO BACK')}</h2>
								</div>
							</Col>
						</Row>

						<Row>
							{/* <Col>
							<div className="d-flex align-items-center">
								<div>
									<img
										src={
											!isImageURLNull(showDetails.movie_image_url_1)
												? showDetails.movie_image_url_1
												: movieDef
										}
										alt="poster"
										height="300"
										className=""
									/>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<p className="fw-bold mb-2">
										{showDetails.movie_title}{' '}
										{showDetails.SessionDimension ? showDetails.SessionDimension : ''}{' '}
										{showDetails.movie_lang
											? showDetails.movie_lang.substring(0, 2).toUpperCase()
											: ''}
									</p>
									<p className="fw-reg mb-2">{t('Date')}</p>
									<p className="fw-bold mb-2">
										{moment(showDetails.Showtime).format('DD MMM YYYY')}
									</p>
									<p className="fw-reg mb-2">{t('Show Details')}</p>
									<p className="fw-bold">{moment(showDetails.Showtime).format('HH:mm')}</p>
								</div>
								<div className="d-flex flex-column kumbh px-4">
									<div className="group frl-12 frm-10 frs-8 fw-reg mb-2">
										<span className="border-right pr-2">{showDetails.rating}</span>
										<span className="pl-2">{timeConvert(showDetails.run_time)}</span>
									</div>
									<p className="fw-reg mb-2">{t('Seats')}</p>
									<p className="fw-bold mb-2">
										{seats.length > 0 ? seats.toString() : t('seatlayout_page.seats-not-selected')}
									</p>
								</div>
							</div>
						</Col> */}






							<Col md={8}>
								<div className="ticket-header bb-1 pb-3">
									<Row className="">
										<Col xs={12}>
											<div className="d-flex">
												<img
													src={
														!isImageURLNull(showDetails.movie_image_url_1)
															? showDetails.movie_image_url_1
															: movieDef
													}
													className=""
												/>
												<div className="ml-1 d-flex flex-column align-items-center w-100">
													<Row className="mx-0 w-100 pb-2 pt-1">
														<Col md={5}>
															<span className="fw-bold frs-28 text-limit v3 lh-1">
																{arabic ? showDetails.movie_title_ar : showDetails.movie_title}
																{showDetails.SessionDimension
																	? '(' + showDetails.SessionDimension + ')'
																	: ''}
																{showDetails.movie_lang
																	? '(' + showDetails.movie_lang.substring(0, 2).toUpperCase() + ')'
																	: ''}
															</span>
														</Col>
														<Col md={4}>
															<div className="group px-2">
																<span
																	className={`${arabic ? 'border-left pl-2' : 'border-right pr-2'}`}
																>
																	{showDetails.rating}
																</span>
																<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
																	{timeConvert(showDetails.run_time)}
																</span>
															</div>
														</Col>
													</Row>
													<Row className="mx-0 w-100">
														<Col xs={5}>
															<span>
																<p>{t('seatlayout_page.Date')}</p>
																<h4>{moment.utc(showDetails.Showtime).format('Do MMMM, YYYY')}</h4>
															</span>
														</Col>
														<Col xs={4}>
															<span>
																<p>{t('seatlayout_page.Seats')}</p>
																<h4>
																	{seats.length > 0
																		? seats.toString()
																		: t('seatlayout_page.seats-not-selected')}
																</h4>
															</span>
														</Col>
													</Row>
													<Row className="mx-0 w-100">
														<Col xs={5}>
															<span>
																<p>{t('seatlayout_page.Show Details')}</p>
																<h4>
																	{showDetails.SessionAttribute
																		? translateSessionAttribute(
																				arabic,
																				showDetails.SessionAttribute
																		  )
																		: translateSessionAttribute(arabic, 'premium')}
																	,{moment.utc(showDetails.Showtime).format('HH:mmA')}
																</h4>
															</span>
														</Col>
														<Col xs={4}>
															<span>
																<p>{t('seatlayout_page.voucher-code')}</p>
																<h4>
																	{voucherCodeData
																		? voucherCode
																		: t('seatlayout_page.No Voucher Added')}
																</h4>
															</span>
														</Col>
														{showDetails && showDetails.cinema_has_voucher === 'Y' && (
															<Col xs={3} className="d-flex align-items-center">
																<span
																	className="blue-btn px-4"
																	onClick={() => setShowVoucherPopup(true)}
																>
																	{voucherCodeData
																		? t('seatlayout_page.CHANGE')
																		: t('seatlayout_page.ADD')}{' '}
																	{t('seatlayout_page.VOUCHER')}
																</span>
															</Col>
														)}
													</Row>
													{/* <div className="d-flex flex-column justify-content-between h-100 w-50">
														<div className="label-ticket">
														<h6 className="right">{showDetails.rating}</h6>
														<h6 className="left">{timeConvert(showDetails.run_time)}</h6>
													</div>
													</div>
													<div className="pl-4 d-flex flex-column align-items-start justify-content-between h-100 w-50">
													</div> */}
												</div>
											</div>
										</Col>
									</Row>
								</div>
							</Col>
							<Col style={{pointerEvents: "none"}} md={4}>
							{country_data 
                  &&
                    // country_data.country_short_code === "BH" &&
                    // moment.utc(showDetails?.Showtime).format("DD-MM-YYYY") ==
                    //   "29-01-2022" && 
                      
                      (
                      <Card className="border-0 offer-seatlayout-card">
                        {/* &&
                // !voucherCodeData &&
                // !confirmedBankOfferTicketObj */}
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={
                            confirmedBankOfferTicketObj
                              ? "1"
                              : voucherCodeData
                              ? "2"
                              : "0"
                          }
                          aria-expanded={orderOpen[0]}
                          onClick={() => toggleHandle(0)}
                          className={orderOpen[0] ? "show" : "hidden"}
                        >
                         {t('seatlayout_page.cinepolis-offers')}
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={
                            voucherCodeData
                              ? "2"
                              : confirmedBankOfferTicketObj
                              ? "1"
                              : "0"
                          }
                          in={orderOpen[0]}
                        >
                          <Card.Body className="px-0">
                            <ul className="seatlayout__order-cards-list mx-0">
                              {seatLayout &&
                                seatLayout.length > 0 &&
                                seatLayout.map((x, layoutIndex) => (
                                  <div>
                                    {x.Description && (
                                      <li className="seatlayout__order-card">
                                        <div>
                                          <p className="seatlayout__order-card-heading">
                                            {x.Description}
                                          </p>
                                        </div>

                                        <div className="bg-light-black-2 rounded-lg mxh-10">
                                          <Dropdown className="w-100 h-100 pr-4 dropdown-code">
                                            <Dropdown.Toggle
                                              disabled={
                                                confirmedBankOfferTicketObj ||
                                                voucherCodeData
                                                  ? true
                                                  : false
                                              }
                                              className="dropdownClassic text-truncate frs-12 pr-4"
                                              id="dropdown-basic"
                                            >
                                              {x.selectedCatOffer
                                                ? x.selectedCatOffer
                                                : x.Description}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="dropdownItems frs-12">
                                              {seatLayout &&
                                                seatLayout.length > 0 &&
                                                seatLayout[layoutIndex]
                                                  .cinepolisOffersPrices &&
                                                seatLayout[layoutIndex]
                                                  .cinepolisOffersPrices
                                                  .length > 0 &&
                                                seatLayout[
                                                  layoutIndex
                                                ].cinepolisOffersPrices
                                                .filter((i)=>{
                                                  // console.log(i.DisplayCinema.splice(","), "PPPPPPPPPPPPPPPPPPPPPPPPPPPP")
                                                  return i.Description != x.Description

                                                })
                                                .filter((j)=>{
                                                  if (j.DisplayCinema != null && j.DisplayCinema.split(",").includes(cinema_id) == true) {
                                                   return true
                                                     
                                                  } else {
                                                    return false
                                                  }
                                                })
												.filter((j)=>{
													if (j.DisplayCinema != null && j.DisplayCinema.split(",").includes(cinema_id) == true && j.Description != null && j.Description.split(" ").includes("PROMO") == true
													&& j.Description != null && j.Description.split(" ").includes("6") == true
													 && moment.utc(showDetails ? showDetails.Showtime : null).isAfter(moment.utc("2022-04-02T00:00:00.000Z")) == true
													) {
													 return true
													   
													} else {
													  return false
													}
												  })
                                                .map(
                                                  (type, index) => (
                                                    <Dropdown.Item
                                                      eventKey={
                                                        type.Description
                                                      }
                                                      className="text-truncate"
                                                      onSelect={() =>
                                                        onSelectTicketTypes(
                                                          type,
                                                          layoutIndex,
                                                          ""
                                                        )
                                                      }
                                                    >
                                                      {arabic ? type.DisplayNameAr && type.DisplayNameAr != "None" ?  type.DisplayNameAr.toUpperCase() : type.Description.toUpperCase()
                                                    :
                                                    type.DisplayName && type.DisplayName != "None" ?  type.DisplayName.toUpperCase() : type.Description.toUpperCase()  
                                                    }
                                                    </Dropdown.Item>
                                                  )
                                                )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                        {/* <div className="seatlayout__order-card-remove">
                    <div>
                      <img src={Bcross} className="icon-18" alt="close" />
                    </div>
                  </div> */}
                                      </li>
                                    )}
                                  </div>
                                ))}
                            </ul>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
							</Col>
						</Row>
						{voucherCodeData && voucherCodeData.isBogo && (
							<Row className="mb-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="success">
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.bogo-message')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						{voucherSeatsExceededError && (
							<Row className="mb-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert
										variant="danger"
										onClose={() => setVoucherSeatsExceededError(false)}
										dismissible
									>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>
											{t('seatlayout_page.You can select only')}{' '}
											{voucherCodeData.isBogo
												? voucherCodeData.numberOfRedemptionsRemaining * 2
												: voucherCodeData.numberOfRedemptionsRemaining}{' '}
											{t('seatlayout_page.Seats')}
										</p>
									</Alert>
								</Col>
							</Row>
						)}
						{showSeatLimitError && (
							<Row className="my-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="danger" onClose={() => setShowSeatLimitError(false)} dismissible>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.errors.seat-limit-err')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						{showSeatLimitError && (
							<Row className="my-3 d-none d-md-flex sticky-alert-top">
								<Col>
									<Alert variant="danger" onClose={() => setShowSeatLimitError(false)} dismissible>
										<Alert.Heading>{t('seatlayout_page.errors.imp-msg')}</Alert.Heading>
										<p>{t('seatlayout_page.errors.seat-limit-err')}</p>
									</Alert>
								</Col>
							</Row>
						)}
						<Row className="">
							<Col>
								<div className="position-relative">
									<div className="stw">
										<img className="img-fluid" src={screen} alt="Screen" />
									</div>
									<div className="stw-text">{showDetails.ScreenName}</div>
								</div>
							</Col>
						</Row>

						{seatLayout && seatLayout.length > 0 && (
							<Row className="mb-4">
								<Col className="table-responsive" id="seat-layout-table">
									<Table borderless responsive className="first-table">
										{/* Section loop, premium, macro xe etc. */}
										{seatLayout.map((seatTypes, seatTypeIndex) => (
											<tr>
												<td>
													{/* <div className="shd">
                        <span>{seatTypes.Description}</span>
                      </div> */}
													<div class="category-title">
														<span>{seatTypes.Description}</span>
													</div>
													<tr>
														<td className="second-table">
															<Table borderless>
																{seatTypes.Rows.map((singleRow, rowIndex) => (
																	<tr key="index" className="">
																		<td className="blurred-white ls-2 fw-reg frxl-20 frl-18 frm-16 frs-14  text-center cell">
																			{singleRow.PhysicalName}
																		</td>
																	</tr>
																))}
															</Table>
														</td>
														<td className="second-table">
															<Table borderless>
																{seatTypes.Rows.map((singleRow, rowIndex) => (
																	<tr key="index" className="seats">
																		{singleRow.Seats.map((singleSeat, seatIndex) =>
																			singleSeat.coupleSeat ? (
																				singleSeat.coupleSeat &&
																				singleRow.Seats[seatIndex - 1] &&
																				singleRow.Seats[seatIndex - 1].coupleSeat ? null : (
																					<>
																						<td
																							onClick={() =>
																								onSelectSeats(
																									seatTypeIndex,
																									rowIndex,
																									seatIndex,
																									seatTypes,
																									singleSeat,
																									singleRow.Seats[seatIndex + 1].Position
																								)
																							}
																						>
																							<div
																								className={`${getSeatClass(
																									singleSeat.Status,
																									singleSeat
																								)} ${
																									singleSeat.SeatSelected
																										? 'active'
																										: checkSeatValid(
																												singleSeat.Status,
																												seatIndex,
																												rowIndex,
																												seatTypes,
																												singleSeat
																										  )
																										? ''
																										: 'reserved'
																								} ${singleSeat.Status === 4 ? 'empty' : ''}
                                            `}
																							>
																								<span>
																									{singleSeat.SeatSelected ? singleSeat.Id : ''}
																								</span>
																								<span>
																									{singleSeat.SeatSelected
																										? singleRow.Seats[seatIndex + 1].Id
																										: ''}
																								</span>
																							</div>
																						</td>
																					</>
																				)
																			) : (
																				<td
																					onClick={() =>
																						onSelectSeats(
																							seatTypeIndex,
																							rowIndex,
																							seatIndex,
																							seatTypes,
																							singleSeat
																						)
																					}
																				>
																					<div
																						className={`${getSeatClass(
																							singleSeat.Status,
																							singleSeat
																						)} ${
																							singleSeat.SeatSelected
																								? 'active'
																								: checkSeatValid(
																										singleSeat.Status,
																										seatIndex,
																										rowIndex,
																										seatTypes,
																										singleSeat
																								  )
																								? ''
																								: 'reserved'
																						} ${singleSeat.Status === 4 ? 'empty' : ''}
                                        `}
																					>
																						{singleSeat.SeatSelected ? singleSeat.Id : ''}
																					</div>
																				</td>
																			)
																		)}
																	</tr>
																))}
															</Table>
														</td>
													</tr>
												</td>
											</tr>
										))}
									</Table>
								</Col>
							</Row>
						)}

						<Row>
							<Col className="seats-desc py-5">
								<div className="d-flex flex-wrap justify-content-center align-items-center">
									<span>
										<img src={occupiedSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.occupied')}
										</span>
									</span>
									<span>
										<img src={whiteSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.open')}
										</span>
									</span>
									<span>
										<img src={selectedSeat} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.selected')}
										</span>
									</span>
									<span>
										<img src={whiteSofa} alt="img" />
										<span className={`${arabic ? 'pr-2' : 'pl-2'}`}>
											{t('seatlayout_page.chair-types.lounger')}
										</span>
									</span>
									<span>
										<img src={wheelChair} alt="img" />
										{t('seatlayout_page.chair-types.wheelchair')}
									</span>
								</div>
							</Col>
						</Row>

						<Row className="sticky-bar-margin">
							<Col sm={12}>
								{seatSelectError && (
									<Alert variant="dark" className="text-center">
										{seatSelectError.message}
										{t('seatlayout_page.errors.try-later')}
									</Alert>
								)}
							</Col>
						</Row>

						<Row className="align-items-center bgb mt-5 sticky-movie-bar">
							<Col xs={12} className="seat-footer">
								<Row className="py-4">
									<Col md={8}>
										<div className={`d-flex px-3 ${arabic ? 'bl-1' : 'br-1'}`}>
											<div>
												<h3 className="f-grey">{t('seatlayout_page.Total Payable')}</h3>
												{selectedTicketPrice.priceInCents === 0 ? (
													<h1 className="fw-bold">
														{(selectedTicketPrice.priceInCents / currency.divided_by).toFixed(2)}{' '}
														{arabic
															? currency.currency_short_code_ar
															: currency.currency_short_code}
													</h1>
												) : (
													<h1 className="fw-bold">
														{(
															(selectedTicketPrice.priceInCents + bookingFee) /
															currency.divided_by
														).toFixed(2)}{' '}
														{arabic
															? currency.currency_short_code_ar
															: currency.currency_short_code}
													</h1>
												)}
												<h5>
													{t('fnb_page.taxes')}:{' '}
													{(
														(selectedTicketPrice.taxInCents +
															(selectedTicketPrice.priceInCents === 0 ? 0 : bookingFeeTax)) /
														currency.divided_by
													).toFixed(2)}{' '}
													{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
												</h5>
											</div>
											<div className={`flex-center ${arabic ? 'pr-4' : 'pl-4'}`}>
												<h6 className="custom-label">{t('seatlayout_page.Total Bill')}</h6>
											</div>
										</div>
									</Col>
									<Col md={4} className="d-flex justify-content-center align-items-center">
										<span
											className={`blue-btn btn-lg px-5 ${
												selected_seats.length === 0 ||
												(voucherCodeData &&
													voucherCodeData.isBogo &&
													selected_seats.length < voucherCodeData.numberOfRedemptionsRemaining * 2)
													? 'inactive-btn'
													: ''
											}`}
											onClick={()=> selected_seats.length > 0 ? onProceed() : null}
										>
											{showProceedSpinner ? (
												<Spinner animation="border" role="status" className="spinner">
													<span className="sr-only">Loading...</span>
												</Spinner>
											) : (
												t('common.PROCEED')
											)}
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				</Container>

				<Modal
					// show={show}
					// onHide={handleClose}
					centered
					aria-labelledby="contained-modal-title-vcenter"
					className="modal-4"
				>
					<div className="wrapper">
						<div className="r1 bg-light-black">
							<div className="pt-4 mb-40">
								<Col xs={10} sm={8} className="mx-auto">
									<ul className="list-inline feature-links d-flex justify-content-around">
										<li className="list-inline-item">
											<a href="#" id="pnoBtn" className="active" onClick={showPno}>
												PROMOTIONS
											</a>
										</li>
										<li className="list-inline-item">
											<a href="#" id="bankOffersBtn" className="" onClick={showBankOffers}>
												BANK OFFERS
											</a>
										</li>
									</ul>
								</Col>
							</div>
							<div id="pno" className="d-flex flex-column align-items-center">
								<Col xs={11} className="pno-section">
									<Row className="">
										{el16.map((x, index) => (
											<Col sm={6} className="mb-31">
												<div className="w-100 bg-light-black-2 d-flex justify-content-between align-items-center p-2 brds-8">
													<img className="img-fluid mxh-58 mxw-58" src={ip1} alt="Food Item" />
													<div className="d-flex flex-column justify-content-between px-2">
														<p className="kumbh fw-reg frs-14">Win 40% Cashback every minute</p>
														<p className="kumbh fw-bold frs-12 blurred-white-2 ls-1">+ DETAILS</p>
													</div>
													<a href="#" className="blue-btn mxh-31 mxw-70 frxl-14 frs-12 ls-15">
														APPLY
													</a>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</div>
							<div id="bankOffers" className="d-none flex-column align-items-center">
								<Col xs={11} className="pno-section">
									<Row className="">
										{el16.map((x, index) => (
											<Col sm={6} className="mb-31">
												<div className="w-100 bg-light-black-2 d-flex justify-content-between align-items-center p-2 brds-8">
													<img className="img-fluid mxh-58 mxw-58" src={ip1} alt="Food Item" />
													<div className="d-flex flex-column justify-content-between px-2">
														<p className="kumbh fw-reg frs-14">
															Get 40SAR Cashback on Mastercard Elite cards
														</p>
														<p className="kumbh fw-bold frs-12 blurred-white-2 ls-1">+ DETAILS</p>
													</div>
													<a href="#" className="blue-btn mxh-31 mxw-70 frxl-14 frs-12 ls-15">
														APPLY
													</a>
												</div>
											</Col>
										))}
									</Row>
								</Col>
							</div>
						</div>
						<div className="r2 bg-light-black-2 d-flex justify-content-center align-items-center mxh-107">
							<div className="d-flex kumbh">
								<span className="fw-reg blurred-white frs-14 frxl-16 pr-3">
									DISCOUNT <br />
									CODE
								</span>
								<div className="d-flex mxh-47 bg-greyish brds-8">
									<input type="text" className="h-100 code-input px-3" />
									<a
										href="#"
										className="blue-btn ls-2 h-100 fw-bold frs-12 frm-14 mxw-90"
										/*onClick={handleClose}*/
									>
										APPLY
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<Modal
					show={showVoucherPopup}
					onHide={() => setShowVoucherPopup(false)}
					centered
					aria-labelledby="contained-modal-title-vcenter"
					className="modal-4 for-btn"
				>
					<div className="wrapper">
						<div className="bg-light-black">
							<Modal.Header closeButton className={`p-0 border-0 pt-2 pr-2`}></Modal.Header>
							<div className="text-center py-md-5 px-md-5 px-sm-1 my-5 px-3">
								<h4 className="font-weight-bold">{t('seatlayout_page.voucher-popup.title')}</h4>
								<p className="px-md-5 pt-3">{t('seatlayout_page.voucher-popup.subtitle')}</p>
							</div>
							<div className="kumbh py-4 bg-greyish px-3">
								<h6 className="text-center">{t('seatlayout_page.voucher-popup.enter-code')}</h6>
								{voucherCodeError && voucherCodeError === 'error' && (
									<span className="form-error-text d-block text-align-center text-center">
										{t('seatlayout_page.errors.invalid-voucher')}
									</span>
								)}
								<div className="d-flex flex-column flex-sm-row justify-content-sm-center">
									<input
										type="text"
										onChange={(e) => setVoucherCode(e.target.value)}
										className="mx-auto mx-sm-4 code-input voucher-input px-3 py-2 mr-4 mb-3 mb-sm-0"
									/>
									<a
										href="#"
										className="mx-auto mx-sm-0 blue-btn px-4 frs-16"
										onClick={voucherHandler}
									>
										{voucherCodeLoading ? (
											<Spinner animation="border" variant="light" role="status">
												<span className="sr-only">Loading...</span>
											</Spinner>
										) : (
											t('common.APPLY')
										)}
									</a>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		</div>
	);
}

export default withNamespaces()(SeatLayout);
