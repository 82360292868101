import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNowPlayingMovies } from '@store/movies/moviesActions';
import { useHistory } from 'react-router-dom';
import { timeConvert, isImageURLNull } from '@helper/formatting.js';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
import { Row, Container, Col } from 'react-bootstrap';
import CSpinner from '@components/partials/Spinner';

import movieDef from '@assets/imgs/movie-default-portrait.jpg';

function Movies({ t }) {
	return (
		<div className="movie_listing_container">
			<MovieHeader t={t} />
			<MovieListings t={t} />
		</div>
	);
}

const MovieListings = ({ t }) => {
	const { arabic, setArabic } = useContext(globalConfigContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const [showSpinner, setShowSpinner] = useState(false);

	const currentLocation =  window.location.href
	// console.log(currentLocation, "currentLocationcurrentLocationcurrentLocationcurrentLocationcurrentLocationcurrentLocation")

	useEffect(() => {
		setShowSpinner(true);
		dispatch(fetchNowPlayingMovies({ today: 'Y', kiosk: 'Y' })).finally(() =>
			setShowSpinner(false)
		);
	}, []);

	const movies = useSelector((state) => state.movies.now_playing_movies);

	return (
		<div>
			<CSpinner active={showSpinner} />
			<Container fluid>
				<div>
					<div className="movie_listings">
						{/* <GoBack to="prev" /> */}
						<Row>
							{movies.length > 0 ? (
								movies
								.filter((item)=>{
									let splitedItem = item?.SalesChannels.split(";") 
									// console.log(splitedItem.includes('KIOSK'), "PPPPPPPPPPPPPPPPPPPPPPPPPPPPP")

									if(splitedItem.includes('KIOSK') == true){
									return true
									}
								})
								.map((m) => <MovieCard m={m} t={t} />)
							) : (
								<div className="go_back">
									<h2 className="big_text">{t('movie_listing.No Movies Found')}</h2>
								</div>
							)}
						</Row>
						<GoBack to="prev" />
					</div>
				</div>
			</Container>
		</div>
	);
};
const MovieCard = ({ m, t }) => {
	const { arabic, currency } = useContext(globalConfigContext);
	const history = useHistory();

	const onClickBookTickets = (Id) => {
		history.push('/movie-details/' + Id);
	};
	return (
		<Col xs={4} className="mt-4 mb-3 ml-col">
			<div className="card_container">
				<div className="movie_pic">
					<img src={!isImageURLNull(m.movie_image_url_1) ? m.movie_image_url_1 : movieDef} />
				</div>
				<div className="movie_info">
					{/* <div className="movie_name">{m.movie_title}</div>
					<div className="right_side">
						<div className="right_top">
							{m.rating} | {timeConvert(m.run_time)}
						</div>
						<div className="right_bot">English, Arabic</div> 
					</div> */}
					<Col xs={6} className="px-2">
						<span className="text-limit v3 fw-bold frs-16">
							{arabic ? m.movie_title_ar : m.movie_title}
						</span>
						{/* For Language? */}
						{/* <span className="kumbh fw-bold frxl-14 frl-12 frm-10 frs-8 text-truncate">{movie.movie_lang}</span> */}
					</Col>
					<Col xs={6} className="px-2 d-flex justify-content-end align-items-end">
						<div className="group px-2">
							<span className={`${arabic ? 'border-left pl-1' : 'border-right pr-1'}`}>
								{m.rating}{' '}
							</span>
							<span className={`${arabic ? 'pr-1' : 'pl-1'}`}>{timeConvert(m.run_time)}</span>
						</div>
					</Col>
				</div>
				<p onClick={() => onClickBookTickets(m.ID)} className="blue-btn w-100 frs-16">
					{t('movie_listing.BOOK TICKETS')}
				</p>
			</div>
		</Col>
	);
};
export default withNamespaces()(Movies);
