import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { Container, Row, Col, Table, Alert, Modal, Spinner } from 'react-bootstrap';
import { stopTimer } from '@store/booking/bookingActions';
import { isImageURLNull } from '@helper/formatting';
import screen from '../assets/imgs/svgs/screen.svg';
import ip1 from '../assets/imgs/ip1.jpg';
import MovieHeader from '@components/partials/MovieHeader';
import { getDiscount } from '@store/booking/bookingSelector';
import { globalConfigContext } from '@context/GlobalConfigContext';
import moment from 'moment';
import QRCode from 'qrcode';
import { PaymentService } from '@apiService/PaymentService';
import { withNamespaces } from 'react-i18next';
import i18n from '../plugins/i18n';
// import Receipt from '@components/Receipt';
import R1 from '@components/R1.js';
import R2 from '@components/R2.js';
import { useReactToPrint } from 'react-to-print';
import movieDef from '@assets/imgs/movie-default-portrait.jpg';
import movieDefLand from '@assets/imgs/movie-default-landscape.jpg';
import axios from 'axios';
// import movieDef from '@assets/imgs/svgs/movie_default.svg';
// import { SOCKET_URL } from '@config';
// var wsUrl = SOCKET_URL;
// var socket = new WebSocket(wsUrl);

var opts = {
	errorCorrectionLevel: 'H',
	type: 'image/jpeg',
	quality: 0.3,
	margin: 1,
	width: '150',
	color: {
		dark: '#000000',
		light: '#FFFFFF',
	},
};

function FinalTicketPage(props) {
	const { arabic, currency } = useContext(globalConfigContext);
	const dispatch = useDispatch();
	const history = useHistory();
	const t = props.t;

	const showDetails = useSelector((state) => state.booking.finalTicketDetails);

	const bookingFee = useSelector((state) => state.booking.bookingFee);

	console.log(bookingFee, "KDFJDSFLDFJDSF")

	const discount = useSelector(getDiscount);
	const onClickBack = () => {
		history.go(-1);
	};
	const [qrcodeData, setQrcodeData] = useState(null);
	const ticketReceiptRef = useRef();
	const paymentReceiptRef = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
		dispatch(stopTimer());
	}, []);

	useEffect(() => {
		if (showDetails && showDetails.bookingId) {
			QRCode.toDataURL(showDetails.bookingId, opts, function (err, url) {
				if (err) throw err;
				setQrcodeData(url);
			});
		} else {
			setQrcodeData(null);
		}
	}, [showDetails]);

	// const initPrintTicketReceipt = useReactToPrint({
	// 	content: () => ticketReceiptRef.current,
	// });

	// const initPrintPaymentReceipt = useReactToPrint({
	// 	content: () => paymentReceiptRef.current,
	// });

	const initPrintPaymentReceipt = (data) => {
		console.log(data, 'utuyiuyi');
		let bookingFeeTax = 0;
		let showData = {
			ScreenName: data.ScreenName,
			transaction_id: data.transaction_id,
			bookingId: data.bookingId,
			ScreenNameAlt: data.ScreenNameAlt,
			movie_title: data.movie_title,
			Showtime: data.Showtime,
			rating: data.rating,
			seats_name: data.seats_name,
			SessionAttribute: data.SessionAttribute,
			currency_short_code: data.currency_short_code,
			transaction_net_price: data.transaction_net_price,
			tax_percent: data.tax_percent,
			total: (data.transaction_net_price / data.divided_by).toFixed(2),
			fnbAmount:
				(data.fnb_taxes_in_cents + data.ticket_taxes_in_cents + bookingFeeTax) /
				data.divided_by.toFixed(2),
			transaction_date_time: data.transaction_date_time,
			booking_fee: data.booking_fee,
			ticketAmount: (data.transaction_net_price / data.number_of_seats / data.divided_by).toFixed(
				2
			),
		};
		// let data = {
		// 	firstName: 'ajit',
		// };
		// //window.location = 'http://localhost:3001/print';
		// axios.get('http://localhost:3001/print', obj).then((response) => ({}));
		//window.location = 'http://localhost:3001/print?jitu=test';
		axios
			.get('http://localhost:3001/print?data=' + JSON.stringify(showData))
			.then((response) => ({}));

		setTimeout(() => {
			history.push('/');
		}, 1000);

		//history.push('/');
	};
	const goHome = () => {
		history.push('/');
	};

	const initPrintTicketReceipt = (data) => {
		console.log(data, 'utuyiuyi');
		let bookingFeeTax = 5;
		let showData = {
			ScreenName: data.ScreenName,
			transaction_id: data.transaction_id,
			bookingId: data.bookingId,
			ScreenNameAlt: data.ScreenNameAlt,
			movie_title: data.movie_title,
			Showtime: data.Showtime,
			rating: data.rating,
			seats_name: data.seats_name,
			SessionAttribute: data.SessionAttribute,
			currency_short_code: data.currency_short_code,
			transaction_net_price: data.transaction_net_price,
			tax_percent: data.tax_percent,
			total: (data.transaction_net_price / data.divided_by).toFixed(2),
			fnbAmount:
				(data.fnb_taxes_in_cents + data.ticket_taxes_in_cents + bookingFeeTax) /
				data.divided_by.toFixed(2),
			transaction_date_time: data.transaction_date_time,
			booking_fee: data.booking_fee,
			ticketAmount: (data.transaction_net_price / data.number_of_seats / data.divided_by).toFixed(
				2
			),
		};
		// let data = {
		// 	firstName: 'ajit',
		// };
		// //window.location = 'http://localhost:3001/print';
		// axios.get('http://localhost:3001/print', obj).then((response) => ({}));
		//window.location = 'http://localhost:3001/print?jitu=test';
		axios
			.get('http://localhost:3001/print?data=' + JSON.stringify(showData))
			.then((response) => ({}));
		initPrintPaymentReceipt(data);
		//history.push('/');
	};

	return (
		<div className="">
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 1000,
					right: 0,
				}}
			>
				<MovieHeader />
			</div>

			{/* <div ref={receiptRef} className="receipt">
				<Receipt data={showDetails} />
			</div> */}

			{/* Ticket Receipt */}
			<div className="receipt">
				<R1 data={showDetails} ref={ticketReceiptRef} />
			</div>

			{/* Payment Receipt */}
			<div className="receipt">
				<R2 data={showDetails} ref={paymentReceiptRef} />
			</div>

			<div className="receipt-overlay" />

			<div className="checkout-page-container">
				<Container fluid className="py-3 mb-4">
					<Row className="d-flex justify-content-center my-5 ">
						<Col xs={6}>
							<h1 className="fw-bold text-center">
								{t('checkout.Your booking is successful, enjoy the show!')}
							</h1>
						</Col>
					</Row>
					<Row className="fnb-checkout">
						<Col xs={8} className="mx-auto">
							<Row className="mxh-70 bg-pb d-flex align-items-center py-3">
								<Col>
									<p className="fw-bold text-center frs-24">{t('checkout.YOUR TICKET')}</p>
								</Col>
							</Row>
							<Row>
								<Col className="px-0">
									<div className="hero">
										<div className="imgOverlay d-flex justify-content-center">
											<img
												className="bg-img"
												src={
													!isImageURLNull(showDetails.movie_image_url_2)
														? showDetails.movie_image_url_2
														: movieDefLand
												}
											/>
										</div>
										<div className="hero-text px-3 d-flex flex-column justify-content-end">
											<Container fluid>
												<Row className="pb-4">
													<Col>
														<Row className="pb-3">
															<Col xs={8}>
																<div className="d-flex align-items-center">
																	<img
																		src={
																			!isImageURLNull(showDetails.movie_image_url_1)
																				? showDetails.movie_image_url_1
																				: movieDef
																		}
																		alt="title"
																		className="moviePoster"
																	/>
																	<div
																		className={`d-flex flex-column justify-content-end fw-reg frs-12 ${
																			arabic ? 'pr-3' : 'pl-3'
																		}`}
																	>
																		<p className="fw-bold frs-18">
																			{arabic
																				? showDetails.movie_title_ar
																				: showDetails.movie_title}
																		</p>
																		<p className="mb-2">{showDetails.cinema_name}</p>
																		<p className="mb-2">
																			{moment.utc(showDetails.Showtime).format('DD-MM-YY')}
																		</p>
																		<p className="mb-2">
																			{t('fnbconfirm.Seats')} {showDetails.seats_name}
																		</p>
																		<p className="">{showDetails.SessionAttribute}</p>
																	</div>
																</div>
															</Col>
															<Col
																xs={4}
																className="d-flex flex-column justify-content-end align-items-end frs-12 fw-reg text-center"
															>
																<img src={qrcodeData} className="qr" />
																<p className="">{t('checkout.Scan this code and recieve')}</p>
																<p className="">{t('checkout.the ticket on your phone')}</p>
															</Col>
														</Row>
													</Col>
												</Row>
											</Container>
										</div>
									</div>
								</Col>
							</Row>

							<Row className="bgb py-5 mb-4">
								<Col xs={8} className="br-1">
									<div className="px-3 frs-20 fw-bold">
										<p className="ls-2 pb-3">{t('checkout.Order Details')}</p>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Tickets')} {showDetails.seats_name}
											</span>
											<span className="frs-24">
												{(showDetails.ticket_price_in_cents - showDetails.ticket_taxes_in_cents) /
													currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Food and Beverages')} (x{showDetails.fnb_items_count})
											</span>
											<span className="frs-24">
												{(showDetails.fnb_price_in_cents - showDetails.fnb_taxes_in_cents) /
													currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										<div className="d-flex justify-content-between pb-3 bb-1 mb-3">
											<span className="blurred-text">
												{t('checkout.Taxes')}
												<br />
												<span className="blurred-text-2">({showDetails.tax_percent}%)</span>
											</span>
											<span className="frs-24">
												{(
													(showDetails.fnb_taxes_in_cents +
														showDetails.ticket_taxes_in_cents +
														(showDetails.booking_fee -
															showDetails.booking_fee / (1 + showDetails.tax_percent / 100))) /
													currency.divided_by
												).toFixed(2)}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
										{discount > 0 && (
											<div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
												<span className="blurred-text">{t('checkout.discount-applied')}</span>
												<span className="fw-bold frs-24">
													{discount / currency.divided_by}{' '}
													{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
												</span>
											</div>
										)}
										{/* <div className="pb-3 bb-1 mb-3 d-flex justify-content-between">
											<span className="blurred-text">{t('checkout.Booking Fee')}</span>
											<span className="frs-24">
												{(
													(showDetails.booking_fee -
														(showDetails.booking_fee -
															showDetails.booking_fee / (1 + showDetails.tax_percent / 100))) /
													currency.divided_by
												).toFixed(2)}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div> */}
										<div className="pb-3 d-flex justify-content-between bb-1">
											<span className="blurred-text">{t('collect_ticket.Total')}</span>
											<span className="frs-24">
												{showDetails.transaction_net_price / currency.divided_by}{' '}
												{arabic ? currency.currency_short_code_ar : currency.currency_short_code}
											</span>
										</div>
									</div>
								</Col>
								<Col xs={4}>
									<div className="h-100 w-100 d-flex flex-column justify-content-between frs-28 ml-4 fw-bold">
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Booking Date')}</p>
											<p>{moment.utc(showDetails.transaction_date_time).format('DD/MM/YYYY')}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Booking Time')}</p>
											<p>{moment.utc(showDetails.transaction_date_time).format('h:mm A')}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Payment Method')}</p>
											<p>{showDetails.CardNameEnglish}</p>
										</span>
										<span>
											<p className="frs-16 blurred-text">{t('checkout.Ticket ID')}</p>
											<p>{showDetails.bookingId}</p>
										</span>
									</div>
								</Col>
							</Row>

							<Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										onClick={() => initPrintTicketReceipt(showDetails)}
									>
										{t('checkout.PRINT TICKET')}
									</span>
								</Col>
							</Row>
							{/* <Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										onClick={() => initPrintPaymentReceipt(showDetails)}
									>
										{t('checkout.PRINT PAYMENT RECEIPT')}
									</span>
								</Col>
							</Row> */}
							<Row className="mt-5 justify-content-center">
								<Col className="text-center">
									<span
										className="d-inline-block ls-3 frs-24 fw-bold px-5 blue-btn"
										onClick={goHome}
									>
										{t('checkout.RETURN TO HOMEPAGE')}
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
}

export default withNamespaces()(FinalTicketPage);
