import {
    FETCH_COMING_SOON_MOVIES, FETCH_COMING_SOON_ERROR, FETCH_NOW_PLAYING_MOVIES, FETCH_NOW_PLAYING_ERROR, FETCH_BANNERS, FETCH_BANNERS_ERROR,
    FETCH_MOVIE_DETAILS, FETCH_MOVIE_DETAILS_ERROR, FETCH_SHOW_TIMES, FETCH_SHOW_TIMES_ERROR, FETCH_CURRENT_MOVIE_SHOW_DATES,
    SET_MOVIE_DETAILS_LOADER,
    SET_SHOW_TIMES_LOADER,
    CLEAR_MOVIE_DETAILS_ERROR
} from "./moviesTypes";

const initialState = {
    now_playing_movies: [],
    now_playing_error: null,
    movie_details: [],
    movie_details_error: null,
    show_times: [],
    show_times_error: null,
    current_movie_show_dates: null,
    show_times_loader: false,
    movie_details_loader: false
}

const moviesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOW_PLAYING_MOVIES:
            return {
                ...state,
                now_playing_movies: action.payload,
                error: action.payload
            }
        case FETCH_NOW_PLAYING_ERROR:
            return {
                ...state,
                now_playing_movies: [],
                now_playing_error: action.payload
            }
        case FETCH_MOVIE_DETAILS:
            return {
                ...state,
                movie_details: action.payload,
                movie_details_loader: false
            }
        case FETCH_MOVIE_DETAILS_ERROR:
            return {
                ...state,
                movie_details_error: action.payload,
                movie_details_loader: false
            }
        case FETCH_SHOW_TIMES:
            return {
                ...state,
                show_times: action.payload,
                show_times_loader: false
            }
        case FETCH_SHOW_TIMES_ERROR:
            return {
                ...state,
                show_times_error: action.payload,
                show_times_loader: false
            }
        case FETCH_CURRENT_MOVIE_SHOW_DATES:
            return {
                ...state,
                current_movie_show_dates: action.payload
            }
        case SET_SHOW_TIMES_LOADER:
            return {
                ...state,
                show_times_loader: action.payload
            }
        case SET_MOVIE_DETAILS_LOADER:
            return {
                ...state,
                movie_details_loader: action.payload
            }
        case CLEAR_MOVIE_DETAILS_ERROR:
            return {
                ...state,
                movie_details_error: null
            }
        default: return state
    }
}

export default moviesReducer

