import Api from './Api';
import { createQueryString } from './ApiHelper';

export default {
	GetNowPlayingMovies(payload = {}) {
		const query = createQueryString(payload);
		console.log(query, "DJK:SJFDKLASFJK")
		return Api().get(`movies/now-playing/${query}`);
	},
	GetMovieDetails(payload) {
		return Api().get(`movies/movie-details/${payload.movie_id}`);
	},
	GetShowTimes(payload) {
		let query = createQueryString(payload);
		return Api().get(`movies/show-times/${payload.movie_id}/${query}`);
	},
	GetShowDates(payload) {
		let query = createQueryString(payload);
		return Api().get(`movies/show-dates/${query}`);
	},
};
