import React, { useContext, useEffect } from 'react';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '../../plugins/i18n';
import { Link } from 'react-router-dom';
// import moment from 'moment';
// import 'moment/locale/ar';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown } from 'react-bootstrap';

const lang = window.localStorage.getItem('lang');

if (lang && lang === 'ar') {
	import('@assets/css/arabic.css').then((condition) => {});
	// moment.locale('ar');
} else {
	import('@assets/css/App.css').then((condition) => {});
	// moment.locale('en');
}

const MovieHeader = ({ t }) => {
	const { arabic, setArabic } = useContext(globalConfigContext);

	const currentLocation =  window.location.href

	useEffect(() => {
		if (lang && lang === 'ar') {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
			setArabic(true);
		} else {
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
			setArabic(false);
		}
		i18n.changeLanguage(lang ? lang : 'en');
	}, []);

	const changeLanguage = (lng) => {
		console.log('lang change');

		if (lng === 'ar') {
			window.localStorage.setItem('lang', 'ar');
			document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
		} else {
			window.localStorage.setItem('lang', 'en');
			document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
		}

		window.location.reload();
	};

	return (
		<Container fluid className="high-index">
			<div>
				<div className="movie_header">
					<Link to="/" className="logo"></Link>
					{
						currentLocation == "https://jeddah-kiosk-cinepolis.binarynumbers.io/movies" ? 
						<span className="big_text">{t('common.Cinepolis-Jameah-Plaza-Jeddah')}</span>: 
						null
					}
					{
						currentLocation == "https://kiosk-cinepolis.binarynumbers.io/movies" ? 
						<span className="big_text">{t('common.Al-Hokair-Time')}</span>: 
						null
					}
					{
						currentLocation == "https://dammam-kiosk-cinepolis.binarynumbers.io/movies" ? 
						<span className="big_text">{t('common.OASIS MALL')}</span>: 
						null
					}
					<Dropdown>
						<Dropdown.Toggle
							className="dropdownClassic"
							onChange={(e) => changeLanguage(e.target.value)}
							name="language"
							id="language"
						>
							{lang && lang === 'ar' ? t('common.ARABIC') : t('common.ENGLISH')}
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdownItems">
							<Dropdown.Item value="en" onSelect={() => changeLanguage('en')}>
								{t('common.ENGLISH')}
							</Dropdown.Item>
							<Dropdown.Item value="ar" onSelect={() => changeLanguage('ar')}>
								{t('common.ARABIC')}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
		</Container>
	);
};

export default withNamespaces()(MovieHeader);
